import React, { useRef, useEffect } from 'react';
import EasyMDE from 'easymde';
import hljs from 'highlight.js';

import 'easymde/dist/easymde.min.css';
import 'github-markdown-css';
import 'highlight.js/styles/github.css';

export function EasyMDEditor({ content, onContentChange, onBlur }) {
    const editor = useRef(null);

    useEffect(() => {
        editor.current = new EasyMDE({
            element: document.getElementById('editor'),
            spellChecker: false,
            initialValue: content,
            placeholder: 'Type here...',
            previewClass: ['editor-preview', 'markdown-body'],
            renderingConfig: {
                codeSyntaxHighlighting: true,
                hljs
            }
        });

        const cm = editor.current.codemirror;
        cm.focus();
        cm.setCursor(cm.lineCount(), 0);

        function handleOnBur() {
            onBlur(editor.current.value());
        }

        function handleOnChange() {
            onContentChange(editor.current.value());
        }

        cm.on('blur', handleOnBur);
        cm.on('change', handleOnChange);

        return () => {
            cm.off('blur', handleOnBur);
            cm.off('change', handleOnChange);
            editor.current.toTextArea();
        };
    }, []);

    return <textarea id="editor" />;
}

export default EasyMDEditor;
