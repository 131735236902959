import removeMarkdown from 'remove-markdown';

function notesReducer(state = [], action) {
    switch (action.type) {
        case 'LOAD': {
            return action.payload;
        }
        case 'CREATE': {
            const now = new Date().getTime();
            const newNote = {
                ...action.$set,
                id: action.id,
                createdAt: now,
                updatedAt: now,
                content: ''
            };
            const plainContent = removeMarkdown(newNote.content);
            const [title, preview] = plainContent.split('\n').filter(x => !!x);

            newNote.title = title;
            newNote.preview = preview;

            return [...state, newNote];
        }
        case 'UPDATE': {
            return state.map(note => {
                if (action.id !== note.id) return note;

                const newNote = {
                    ...note,
                    ...action.$set,
                    updatedAt: new Date().getTime()
                };
                const plainContent = removeMarkdown(newNote.content);
                const [title, preview] = plainContent
                    .split('\n')
                    .filter(x => !!x);

                newNote.title = title;
                newNote.preview = preview;

                return newNote;
            });
        }
        case 'DELETE': {
            const notes = state.filter(note => action.id !== note.id);

            return notes;
        }
        default:
            return state;
    }
}

export default notesReducer;
