import React from "react";
import styled from "styled-components";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;

        width: 100%;
        height: 100%;

        background-color: rgba(0, 0, 0, 0.5);
    }
`;

const Box = styled.div`
    position: relative;
    z-index: 200;

    min-width: 500px;
    margin: 0 16px;
    padding: 20px 30px;

    background-color: #fff;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 5px;
    right: 5px;

    padding: 10px;

    border: 0;
    background-color: #fff;
`;

function Modal(props) {
    return (
        <Container>
            <Box>
                <CloseButton type="button" onClick={props.onCloseModal}>
                    x
                </CloseButton>

                {props.children}
            </Box>
        </Container>
    );
}

export default Modal;
