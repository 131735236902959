import styled from "styled-components";

const Button = styled.button`
  display: inline-block;
  padding: 3px;

  border-radius: 4px;
  border: 0;
  background: #fff;
`;

export default Button;
