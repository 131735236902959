import styled from 'styled-components';

const SidebarButtons = styled.header`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: space-between;

  font-size: 14px;

  div:nth-child(2) {
    justify-self: end;
  }

  div:nth-child(1) :not(:first-child) {
    margin-left: 10px;
  }

  div:nth-child(2) :not(:last-child) {
    margin-right: 10px;
  }

  span {
    color: #999;
  }
`;

export default SidebarButtons;
