import styled from 'styled-components';

const NotesList = styled.ul`
    margin: 0;
    padding: 0;

    list-style-type: none;
`;

export default NotesList;
