import styled from "styled-components";

const Sidebar = styled.aside`
  height: 100vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  border-right: 1px solid #efefef;
`;

export default Sidebar;
