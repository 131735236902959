import React from "react";
import styled from "styled-components";
import { removeSeconds } from "../../utils/date";

const EditorContainer = styled.article`
  padding: 30px;
  height: 100vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`;

const EditorHeader = styled.header`
  margin-bottom: 20px;

  text-align: center;

  > p {
    font-size: 11px;
    color: #999;
  }
`;

function Editor(props) {
  return (
    <EditorContainer>
      <EditorHeader>
        <p>{removeSeconds(new Date(props.date).toLocaleString())}</p>
      </EditorHeader>

      <div>{props.children}</div>
    </EditorContainer>
  );
}

export default Editor;
