import styled from 'styled-components';

const Input = styled.input`
    padding: 7px 10px;
    ${props => props.full && `width: 100%;`}

    border: 1px solid #efefef;
`;

export default Input;
