import React, {
    Fragment,
    useState,
    useReducer,
    useEffect,
    useRef
} from 'react';
import ReactDOM from 'react-dom';
import FlexSearch from 'flexsearch';
import 'flexsearch/lang/de';
import 'flexsearch/lang/en';

import './styles.css';

import {
    saveSelectedNoteId,
    getNotesFromStorage,
    getSelectedNoteId,
    saveAccountToken,
    getAccountToken,
    saveNotes
} from './storage/localStorage';
import notesReducer from './store/notes';

import NoteItem from './components/NoteItem';
import Sidebar from './components/Sidebar';
import SidebarButtons from './components/SidebarButtons';
import Button from './components/Button';
import AppLayout from './components/AppLayout';
import Editor from './components/Editor';
import EasyMDEditor from './components/Editor/EasyMDEditor';
import NotesList from './components/NotesList';
import Modal from './components/Modal';
import Space from './components/Space';
import Input from './components/Input';

function getNoteById(noteId, notes = []) {
    return notes.find(note => note.id === noteId);
}

function getNextNote(currentNote, notes = []) {
    if (notes.length === 0) return {};

    const currentNoteIndex = notes.findIndex(
        note => note.id === currentNote.id
    );

    return notes[currentNoteIndex + 1] || notes[currentNoteIndex - 1] || {};
}

function App() {
    const searchIndex = useRef(
        new FlexSearch('fast', {
            doc: {
                id: 'id',
                field: ['title', 'preview', 'content']
            }
        })
    );
    const [originalNotes, dispatch] = useReducer(notesReducer, []);
    const [notes, setNotes] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [activeNote, setActiveNote] = useState(null);
    const [creatingAccount, setCreatingAccount] = useState(false);
    const [accountToken] = useState(getAccountToken());
    const [modalOpened, setModalOpened] = useState(false);
    const [syncingAccount, setSyncingAccount] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isCreationEnabled, setIsCreationEnabled] = useState(true);
    const [isSearchingEnabled, setIsSearchingEnabled] = useState(true);
    const [isDeleteEnabled, setIsDeleteEnabled] = useState(true);

    useEffect(() => {
        const notesFromStorage = getNotesFromStorage();
        const selectedNoteIdFromStorage = getSelectedNoteId();
        const selectedNote = getNoteById(
            selectedNoteIdFromStorage,
            notesFromStorage
        );

        dispatch({
            type: 'LOAD',
            payload: notesFromStorage
        });
        setSelectedId(selectedNote ? selectedNoteIdFromStorage : null);
        setActiveNote(selectedNote);
        searchIndex.current.add(notesFromStorage);
    }, []);

    useEffect(() => {
        const hasNotes = !!originalNotes.length;

        saveNotes(originalNotes);
        setNotes(originalNotes);
        setIsSearchingEnabled(hasNotes);

        searchIndex.current.add(originalNotes);

        if (!hasNotes) {
            setSearchTerm('');
            setIsSearching(false);
        }
    }, [originalNotes]);

    useEffect(() => {
        const hasActiveContent = !!activeNote?.content.trim();

        setIsCreationEnabled(!activeNote || hasActiveContent);
        setIsDeleteEnabled(hasActiveContent);
    }, [activeNote]);

    useEffect(() => {
        const activeNote = getNoteById(selectedId, notes);
        saveSelectedNoteId(selectedId);
        setActiveNote(activeNote);
    }, [selectedId, notes]);

    useEffect(() => {
        if (!accountToken) return;

        saveAccountToken(accountToken);
        syncAccount(accountToken);
    }, [accountToken]);

    useEffect(() => {
        setNotes(originalNotes);
    }, [isSearching, originalNotes]);

    useEffect(() => {
        if (!searchTerm) return;

        setSelectedId(null);
    }, [searchTerm]);

    useEffect(() => {
        if (!searchTerm) {
            setNotes(originalNotes);
        } else {
            setNotes(searchIndex.current.search(searchTerm));
        }
    }, [searchTerm, originalNotes]);

    // Update page title
    useEffect(() => {
        document.title = selectedId
            ? `${activeNote?.title ?? ''} - NotesDown`
            : 'NotesDown';
    }, [selectedId, activeNote]);

    function handleEditorChange(content) {
        dispatch({
            type: 'UPDATE',
            id: selectedId,
            $set: {
                content
            }
        });
    }

    function handleEditorBlur(content) {
        if (!content.trim()) {
            deleteNote(selectedId);
        }
    }

    function handleAddNote() {
        const id = new Date().getTime();

        dispatch({
            type: 'CREATE',
            id
        });
        setSelectedId(id);
    }

    function handleDeleteNote() {
        const c = window.confirm('Are you sure?');
        if (!c) return;

        deleteNote(selectedId);
    }

    function deleteNote(id) {
        searchIndex.current.remove(getNoteById(id, originalNotes));
        dispatch({
            type: 'DELETE',
            id
        });

        const nextNote = getNextNote(activeNote, notes);
        const nextSelectedId = nextNote ? nextNote.id : null;
        setSelectedId(nextSelectedId);
    }

    function handleSelectNote(item) {
        setSelectedId(item.id);
    }

    async function handleCreateAccount() {
        setCreatingAccount(true);

        // const res = await fetch("/api/create-account");
        // const data = await res.json();

        setCreatingAccount(false);
        // setAccountToken(data.id);
        setModalOpened(true);
    }

    function handleCloseModal() {
        setModalOpened(false);
    }

    async function syncAccount(token) {
        setSyncingAccount(true);

        // const res = await fetch("/api/sync-notes", {
        //     method: "post",
        //     body: JSON.stringify({
        //         notes,
        //         token
        //     })
        // });
    }

    function handleSearch() {
        setIsSearching(!isSearching);
    }

    function handleSearchInput(ev) {
        const value = ev.target.value;

        setSearchTerm(value);
    }

    return (
        <Fragment>
            {modalOpened && (
                <Modal onCloseModal={handleCloseModal}>
                    <h3>Your account token is:</h3>

                    <h2>
                        <strong>{accountToken}</strong>
                    </h2>

                    <p>
                        Write down your account token in a safe place and don't
                        lose it. It's your unique identifier to use our service.
                        No email, no password. Your data is private!
                    </p>

                    <p>
                        Use this token in order to sync your notes across other
                        devices.
                    </p>
                </Modal>
            )}

            <AppLayout>
                <Sidebar>
                    <Space>
                        <SidebarButtons>
                            <div>
                                <Button
                                    type="button"
                                    onClick={handleAddNote}
                                    disabled={!isCreationEnabled}
                                >
                                    New
                                </Button>
                                <Button
                                    type="button"
                                    onClick={handleDeleteNote}
                                    disabled={!isDeleteEnabled}
                                >
                                    Delete
                                </Button>
                                <Button
                                    type="button"
                                    onClick={handleSearch}
                                    disabled={!isSearchingEnabled}
                                >
                                    Search
                                </Button>
                            </div>
                            <div>
                                <span>
                                    {!accountToken
                                        ? 'Not synced'
                                        : syncingAccount
                                        ? 'Syncing...'
                                        : 'Synced!'}
                                </span>
                                {!accountToken && (
                                    <Button
                                        type="button"
                                        onClick={handleCreateAccount}
                                        disabled={creatingAccount}
                                    >
                                        Create Account
                                    </Button>
                                )}
                            </div>
                        </SidebarButtons>
                    </Space>
                    <div>
                        {isSearching && (
                            <Space>
                                <Input
                                    type="search"
                                    onChange={handleSearchInput}
                                    placeholder="Search..."
                                    autoFocus
                                    full
                                />
                            </Space>
                        )}
                        <NotesList>
                            {notes
                                .sort((a, b) => b.updatedAt - a.updatedAt)
                                .map((item, key) => (
                                    <NoteItem
                                        title={item.title}
                                        date={item.updatedAt}
                                        preview={item.preview}
                                        isActive={item.id === selectedId}
                                        onClick={() => handleSelectNote(item)}
                                        key={key}
                                    />
                                ))}
                        </NotesList>
                    </div>
                </Sidebar>
                {activeNote && (
                    <Editor date={activeNote.updatedAt}>
                        <EasyMDEditor
                            onContentChange={handleEditorChange}
                            onBlur={handleEditorBlur}
                            key={activeNote.id}
                            content={activeNote.content || ' '}
                        />
                    </Editor>
                )}
            </AppLayout>
        </Fragment>
    );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
