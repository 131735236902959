function withContent(note) {
    return !!note.content.trim();
}

export function getNotesFromStorage() {
    try {
        return JSON.parse(
            window.localStorage.getItem("notes-down__notes")
        ).filter(withContent);
    } catch (err) {
        return [];
    }
}

export function deleteNotes() {
    window.localStorage.removeItem("notes-down__notes");
}

export function saveNotes(notes = []) {
    window.localStorage.setItem("notes-down__notes", JSON.stringify(notes));
}

export function saveSelectedNoteId(id) {
    window.localStorage.setItem(`notes-down__selected`, id || null);
}

export function getSelectedNoteId() {
    const id = window.localStorage.getItem("notes-down__selected");

    return id ? Number(id) : null;
}

export function saveAccountToken(token) {
    if (!token) return;

    window.localStorage.setItem("notes-down__token", token);
}

export function getAccountToken() {
    return window.localStorage.getItem("notes-down__token");
}
