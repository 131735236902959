import React from "react";
import styled from "styled-components";
import { removeSeconds } from "../../utils/date";

const isToday = someDate => {
    const today = new Date();
    return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    );
};

const ItemContainer = styled.li`
    padding: 15px 25px;

    color: #262315;

    background-color: #fff;
    border-bottom: 1px solid #efefef;

    ${props =>
        props.isActive &&
        `
    background-color: #fee28f;
  `}
`;

const ItemTitle = styled.h3`
    margin: 0;
    margin-bottom: 5px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const ItemDescription = styled.p`
    margin: 0;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

function NoteItem(props) {
    const date = new Date(props.date);

    return (
        <ItemContainer isActive={props.isActive} onClick={props.onClick}>
            <ItemTitle>
                <strong>{props.title || "New Note"}</strong>
            </ItemTitle>
            <ItemDescription>
                <strong>
                    {isToday(date)
                        ? removeSeconds(date.toLocaleTimeString())
                        : date.toLocaleDateString()}
                </strong>{" "}
                {props.preview || "No additional text"}
            </ItemDescription>
        </ItemContainer>
    );
}

export default NoteItem;
